$color-primary: #1a70d5;
$color-secondary: #2e2e66;
$color-black: #101022;
$color-dark: #333439;
$color-light: #ffffff;
$color-green: #1ad54c;
$color-danger: #c70039;

$text-color-primary: #0f0f20;
$text-color-secondary: #6a6a6a;
