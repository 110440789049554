@import "./../assets/fonts/stylesheet.css"; //fonts
@import "~swiper/swiper-bundle";
@import "reset";
@import "variables";
@import "global";
@import "~hamburgers/dist/hamburgers.min.css";

.hamburger {
  padding: 0;
  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    background-color: $color-primary;
  }
}
