@import "variables";

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
p,
blockquote,
hr,
th,
td {
    margin: 0;
    padding: 0;
}
h3,
h4,
h5,
h6 {
    font-weight: 700;
}
h1,
h2 {
    font-weight: 700;
}
small {
    font-weight: 400;
    font-size: 12px;
}
html {
    -webkit-font-smoothing: antialiased;
    font-size: 100%;
    font-family: "SF Pro Display", sans-serif;
    letter-spacing: 0.4px;
}
strong,
b {
    font-weight: 700;
}
p {
    color: $text-color-secondary;
    font-size: inherit;
    line-height: 1.5;
}
blockquote {
    font-size: 14px;
    font-style: italic;
    padding-left: 40px;
    border-left: 1px solid rgba(18, 18, 18, 0.2);
}
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
ul,
ol {
    list-style: none;
}
a {
    text-decoration: none;
    color: currentColor;
}
a:hover {
    text-decoration: underline;
}
a,
button {
    transition: all 0.2s;
    font: inherit;
}
a:hover,
button:hover {
    cursor: pointer;
}
input,
textarea,
button {
    outline: none;
    border: 0;
    background: transparent;
}
body {
    color: $text-color-primary;
    background-color: transparent;
    background-image: url(../assets/linhas.png);
    background-repeat: repeat-y;
    background-position: center center;
    background-size: contain;
}
.clear {
    width: 100%;
    clear: both;
    display: block;
    height: 0px;
}
img {
    max-width: 100%;
    height: auto;
    word-break: break-all;
}
input,
textarea,
select {
    color: inherit;
    font-family: inherit;

    &::placeholder {
        color: currentColor;
        opacity: 0.85;
    }
}
table {
    width: 100%;
}
::selection {
    background: #ccc;
    color: #fff;
}

.post {
    position: relative;
    min-height: 400px;

    &-content {
        font-size: 1.3rem;
        line-height: 2;
        font-weight: 300;
        max-width: 700px;
        margin: 0 auto;
        & > * {
            display: block;
            width: 100%;
            margin-bottom: 30px;
        }
    }
}

.summernote {
    * {
        font-family: "SF Pro Display", sans-serif !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color-primary;
    }

    a {
        color: $color-primary;
        font-weight: 600;
    }

    span,
    p {
        color: $text-color-secondary !important;
        background: transparent !important;
        font-size: 16px !important;
        line-height: 1.5 !important;
        font-weight: 400 !important;
        margin: 0 !important;
        letter-spacing: 0.8px !important;
    }

    img {
        border-radius: 12px;
        box-shadow: 0px 5px 10px #00000059;
    }

    iframe {
        border-radius: 12px;
        box-shadow: 0px 5px 10px #00000059;
        width: 100%;
        min-height: 460px;
    }

    ul {
        list-style-position: inside !important;

        li + li {
            margin-top: 10px;
        }
    }

    .table {
        box-shadow: 0px 3px 6px #00000029;
        width: 100%;
        border-collapse: collapse;
        border-radius: 12px;
        th,
        td {
            border: 0.55px solid rgba(0, 0, 0, 0.1);
            padding: 4px 8px;
        }
    }

    ol {
        list-style: decimal;
    }

    ul {
        list-style: circle;
    }

    .note-float-left {
        margin-right: 16px;
    }

    .note-float-right {
        margin-left: 16px;
    }

    .WebKit-mso-list-quirks-style {
        display: none !important;
    }

    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

.hidden {
    display: none !important;
}

@media screen and (min-width: 1320px) {
    .hidden-desktop1280 {
        display: none !important;
    }
}

@media screen and (min-width: 800px) {
    .hidden-desktop {
        display: none !important;
    }
}

@media screen and (max-width: 768px) {
    .summernote {
        iframe {
            min-height: 240px;
        }

        .note-float-left,
        .note-float-right {
            margin-right: 0px;
            margin-left: 0px;
            width: 100% !important;
            margin-bottom: 16px;
            margin-top: 16px;
        }

        img {
            object-fit: cover;
        }
    }

    .hidden-mobile {
        display: none !important;
    }

    .post {
        &-content {
            font-size: 1.15rem;
            & > * {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
