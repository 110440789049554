@font-face {
    font-family: "SF Pro Display";
    src: url("SFProDisplay-Heavy.woff2") format("woff2"), url("SFProDisplay-Heavy.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    src: url("SFProDisplay-Regular.woff2") format("woff2"), url("SFProDisplay-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    src: url("SFProDisplay-Black.woff2") format("woff2"), url("SFProDisplay-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    src: url("SFProDisplay-Bold.woff2") format("woff2"), url("SFProDisplay-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    src: url("SFProDisplay-Semibold.woff2") format("woff2"), url("SFProDisplay-Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
