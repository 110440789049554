@import "variables";

.container {
    width: 100%;
    padding: 0 5%;
    max-width: 1440px;
    margin: 0 auto;
}

.btn {
    height: 44px;
    line-height: 42px;
    padding: 0 24px;
    min-width: 120px;
    text-align: center;
    border: 1px solid transparent;
    display: inline-block;
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    position: relative;

    &:hover {
        text-decoration: none;
    }

    &-default {
        background: $color-secondary;
        border-color: $color-secondary;
        color: #ffffff;

        &:hover {
            background: $color-primary;
            border-color: $color-primary;
        }
    }

    &-outline {
        border-color: $color-secondary;
        color: $color-secondary;

        &:hover,
        &.active {
            color: #ffffff;
            background-color: $color-secondary;
        }

        &--green {
            &.active,
            &:hover {
                border-color: $color-green;
                background-color: $color-green;
            }
        }
    }

    &-special {
        border-color: $color-dark;
        color: $color-dark;

        span {
            color: currentColor;
            position: relative;
            z-index: 1;
        }

        &::before {
            content: "";
            width: 100%;
            height: 100%;
            opacity: 0.5;
            position: absolute;
            right: -8px;
            bottom: -8px;
            transition: all 180ms ease-in-out;
            z-index: 0;
        }

        &:hover {
            border-color: $color-primary;
            color: #ffffff;
            &::before {
                bottom: 0;
                right: 0;
                opacity: 1;
            }
        }

        &--primary {
            &::before {
                background-color: $color-primary;
            }
        }

        &--secondary {
            border-color: $color-primary;
            color: #ffffff;
            &::before {
                background-color: #f1f1f1;
            }
            &:hover {
                &::before {
                    background-color: $color-primary;
                }
            }
        }
    }
}

.slide-arrows {
    margin-top: 70px;
    position: relative;
    z-index: 2;
    &__item {
        margin: 0;
        padding: 0;
        display: inline-block;

        &--right {
            margin-left: 30px;

            img {
                transform: rotate(180deg);
            }
        }

        &:disabled {
            opacity: 0.4;
            pointer-events: none;
        }
    }

    &__right {
        text-align: right;
    }

    &.white {
        .slide-arrows__item {
            img {
                filter: brightness(0) invert(1);
            }
        }
    }
}

.form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    &-control {
        flex: 0 0 100%;
        width: 100%;
        position: relative;

        input {
            padding: 20px 24px;
            margin: 0;
            background: transparent;
            border: 1px solid #e5e8e8;
            color: #ffffff;
            opacity: 0.5;
            font-size: 14.5px;
            width: 100%;

            &:focus {
                opacity: 1;
            }
        }

        textarea {
            padding: 20px 24px;
            margin: 0;
            background: transparent;
            border: 1px solid #e5e8e8;
            color: #ffffff;
            opacity: 0.5;
            font-size: 14.5px;
            min-height: 120px;
            width: 100%;
            resize: none;

            &:focus {
                opacity: 1;
            }
        }

        & + .form-control {
            margin-top: 12px;
        }

        &__hint {
            position: absolute;
            top: 50%;
            right: 16px;
            color: $color-danger;
            transform: translate(0, -50%);
        }
    }

    &.light {
        .form-control {
            input {
                border-color: $text-color-secondary;
                color: $text-color-primary;
            }
        }
    }
}

.about {
    position: relative;
    overflow: hidden;

    &-wrapper {
        display: flex;
        flex-flow: row;
        align-items: stretch;

        .right,
        .left {
            width: 50%;
            flex: 0 0 50%;
        }

        .right {
            background: #0f0f20;
            padding: 140px 100px 140px 160px;
        }
    }

    &-title {
        margin-bottom: 60px;
        small {
            color: $color-primary;
            font-size: 32px;
            letter-spacing: 15px;
            font-weight: 700;
            white-space: nowrap;
        }
        span,
        strong {
            font-weight: 900;
            font-size: 80px;
            letter-spacing: 4px;
            display: block;
            color: #ffffff;
            line-height: 1;
        }
        strong {
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #ffffff;
            color: transparent;
        }
    }

    &-description {
        color: #ffffff;
        line-height: 2;
        font-size: 16px;
    }

    .btn {
        margin-top: 50px;
    }

    &-carousel {
        min-height: 600px;
        transform: translateX(110px);

        &-item {
            position: relative;
            min-height: 600px;

            img {
                width: 100%;
                object-fit: cover;
                display: block;
                height: 600px;
            }
        }

        &-buttons {
            background: #ffffff;
            padding: 16px;
            position: absolute;
            top: 140px;
            left: 0;
            z-index: 10;
            max-width: 50%;
        }

        &-button {
            position: relative;
            aspect-ratio: 1/1;
            overflow: hidden;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 300ms;
            }

            &::before {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.4);
                z-index: 2;
                opacity: 0;
                pointer-events: none;
                transition: all 300ms;
            }

            &:hover,
            &.active {
                cursor: pointer;

                img {
                    transform: scale(1.08);
                }

                &::before {
                    opacity: 1;
                }
            }
        }
    }
}

.waves {
    position: absolute;
    right: 0;
    width: 50%;
    height: 900px;
    transform: translateY(-25%);
    z-index: -1;
    animation: animatedBackground 300s linear infinite;
    background: url(../assets/icons/waves.svg) center center repeat-x;
    background-size: contain;
    pointer-events: none;
    overflow: hidden;
}

@-webkit-keyframes animatedBackground {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 0;
    }
}

@keyframes animatedBackground {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 0;
    }
}

@media screen and (max-width: 768px) {
    .about {
        .container {
            padding: 0;
        }

        &-title {
            small {
                font-size: 24px;
                letter-spacing: 12px;
            }
            span {
                font-size: 64px;
            }
            strong {
                font-size: 64px;
            }
        }

        &-wrapper {
            flex-flow: wrap;

            .right,
            .left {
                width: 100%;
                flex: 0 0 100%;
                padding: 30px 5%;
            }

            .right {
                background: #0f0f20;
                padding: 30px 5%;
            }
        }

        &-carousel {
            min-height: unset;
            transform: translateX(0);

            &-item {
                position: relative;
                min-height: unset;

                img {
                    width: 100%;
                    object-fit: cover;
                    display: block;
                    height: auto;
                }
            }

            &-buttons {
                background: #ffffff;
                padding: 0;
                position: relative;
                top: unset;
                left: 0;
                z-index: 10;
                max-width: 100%;
                margin: 24px 0;
            }

            &-button {
                position: relative;
                overflow: hidden;
                aspect-ratio: 1/1;
                width: 100%;

                img {
                    display: block;
                    object-fit: cover;
                    transition: all 300ms;
                }

                &::before {
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    background: rgba(0, 0, 0, 0.4);
                    z-index: 2;
                    opacity: 0;
                    pointer-events: none;
                    transition: all 300ms;
                }

                &:hover,
                &.active {
                    cursor: pointer;

                    img {
                        transform: scale(1.08);
                    }

                    &::before {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
